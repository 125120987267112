@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.main {
  background-color: #f4f5f9;
  padding: 50px 100px;
  display: flex;
  gap: 30px;
}

.card-div {
  width: 70%;
  padding: 50px 0 10px;
}

.card-div .inner {
  padding: 0 50px;
}

.box {
  background-color: #fff;
  border-radius: 20px;
}

.info-div {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.info-div .box {
  padding: 30px;
  font-size: 14px;
}

.info-div-sm {
  display: none;
}

.info-div-sm .info-text {
  margin-top: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.info-div-sm .info-btn {
  border: 0px;
  background: transparent;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 30px;
  color: rgb(1, 163, 0);
  font-weight: 600;
}

.menu-list {
  display: flex;
  gap: 5px;
}

.menu-list button {
  width: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.menu-list button div.menu-item {
  border: 1px solid #eee;
  border-radius: 15px 15px 5px 5px;
  margin-bottom: 10px;
}

.menu-list button div.selected {
  background-color: #01a300 !important;
}

.menu-list button div.border {
  height: 5px;
  background-color: transparent;
  border-radius: 20px 20px 5px 5px;
}

.menu-list button div.image {
  padding: 5px 0;
}

.menu-list img {
  height: 50px;
}

.info-item {
  display: flex;
  justify-content: space-between;
}

.info-item span:first-child {
  color: #8c9199;
}

form {
  margin-top: 30px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row-lg {
  display: flex;
  gap: 20px;
}

.input-lg {
  width: 50%;
}

.input-group-sm {
  width: 50%;
  display: flex;
  gap: 20px;
}

.input-sm {
  width: 50%;
}

button.checkoutBtn {
  background-color: #01a300;
  border: none;
  padding: 10px 0;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .main {
    padding: 50px;
  }
}

@media (max-width: 1100px) {
  .main {
    padding: 30px;
    gap: 15px;
  }

  .info-div {
    gap: 15px !important;
  }

  .card-div {
    padding: 30px 0 10px !important;
  }

  .card-div .inner {
    padding: 0 30px;
  }

  .info-div .box {
    padding: 15px !important;
  }
}

@media (max-width: 950px) {
  .main {
    flex-direction: column-reverse;
  }

  .info-div {
    flex-direction: row !important;
    width: 100% !important;
  }

  .card-div {
    width: 100% !important;
  }

  .info-div .box {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .info-div {
    display: none !important;
  }

  .info-div-sm {
    display: block !important;
  }

  .form-row {
    flex-direction: column;
    gap: 0 !important;
  }

  .input-lg {
    width: 100% !important;
  }

  .input-group-sm {
    width: 100% !important;
  }

  .info-item span,
  .info-item p {
    font-size: 14px !important;
  }

  .card-div p {
    font-size: 14px;
  }
}

.checkout-form {
  max-width: 768px;
  width: 100%;
  margin-inline: auto;
  margin-block: 60px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}
.wrapper {
  padding: 20px;
}
.upper-wrapper {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.fields-wrapper {
  max-width: 460px;
}
.input {
  margin-bottom: 28px;
  position: relative;
}
.input label {
  font-size: 14px;
}
.input label span {
  color: #f00;
}
.input input,
.input select {
  margin-top: 2px;
  width: calc(100% - 20px);
  outline: none;
  background-color: transparent;
  padding-inline: 12px;
  /* border: 1px solid transparent; */
  border: 1px solid #ccc;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  transition: 0.3s linear;
  min-height: 40px;
}
.input input:focus,
.input input:hover,
.input select:focus,
.input select:hover {
  border: 1px solid rgb(22, 111, 245);
}
.input .error-field {
  font-size: 12px;
  color: #f00;
  margin: 0;
  position: absolute;
}
.flex-3 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.flex-3 .input {
  width: calc(33% - 10px);
}
.flex-2 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.flex-2 .input {
  width: calc(50% - 10px);
}

/* Remove arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For completeness: Remove arrows in Internet Explorer 10+ */
input[type="number"] {
  -ms-appearance: textfield;
}

.input select {
  width: 100%;
  padding: 11px;
}

.wrapper .checkoutBtn {
  background-color: rgb(22, 111, 245);
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 12px 20px;
  color: white;
  margin-top: 5px;
  width: 100%;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
}

@media (max-width: 520px) {
  .wrapper form {
    margin-top: 30px;
  }
}
@media (max-width: 790px) {
  .checkout-form {
    max-width: none;
    width: 95%;
  }
}
@media (max-width: 768px) {
  .upper-wrapper {
    flex-direction: column;
  }
  .fields-wrapper {
    max-width: none;
  }
  .flex-2,
  .flex-3 {
    flex-direction: column;
    gap: 0;
  }
  .flex-3 .input,
  .flex-2 .input {
    width: 100%;
  }
}

.paymentMethodDiv {
  /* margin-top: 10px; */
  gap: 20px;
  display: flex;
  justify-content: center;
  padding: 10px 0px 0px;
}
.recaptcha {
  display: flex;
  justify-content: center; /* Center the reCAPTCHA horizontally */
  align-items: center; /* Center the reCAPTCHA vertically */
  margin-top: 20px; /* Adjust margin as needed */
}

.recaptcha > div {
  width: 100%;
}

.checkoutBtn {
  margin-top: 20px; /* Adjust margin as needed to match spacing */
  width: 100%; /* Ensure button width is 100% */
  text-align: center; /* Center text within the button */
}
