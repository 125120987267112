main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main section {
  flex: 1;
}

.unauthorized {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 83px);
  text-align: center;
  flex-direction: column;
}
.unauthorized p {
  font-size: 20px;
}

.pay-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 83px);
  text-align: center;
  gap: 12px;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #0c0c0c;
  color: #fff;
}

.header h1 {
  font-size: 22px;
}

.header img {
  height: 50px;
  object-fit: contain;
}

.message img {
  width: 80px;
}

@media screen and (max-width: 560px) {
  .header {
    padding: 5px 10px;
  }

  .header h1 {
    font-size: 14px;
  }

  .header img {
    height: 24px;
  }
}

.google-pay-button,
.apple-pay-button {
  max-width: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0 24px;
  /* text-transform: uppercase; */

  transition: background-color 0.3s ease;
}

.google-pay-button:hover,
.apple-pay-button:hover {
  background-color: #333;
}

.main-header__logo-image {
  width: 15px;
}
